"use strict";

require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.object.freeze");
require("core-js/modules/es6.number.constructor");
var _yunying = require("@/api/yunying");
var _wechats = require("@/api/wechats");
var _constants = require("@/constants");
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 批量修改枚举类型
var MULTI_TYPES = {
  FANS_TAG: 'modify_fans_tag',
  FANS_VALUE: 'modify_fans_value'
};
var _default2 = exports.default = {
  props: {
    ruleForm: {
      type: Object,
      default: function _default() {
        return {
          name: '',
          fansList: [] // 导粉号列表
        };
      }
    },
    // 操作类型
    manageType: {
      type: String,
      default: ''
    },
    // 导粉标签列表
    fansTagList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 多选列表
    multiList: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  created: function created() {
    this.FANS_TYPES_MAP = _constants.FANS_TYPES_MAP;
  },
  data: function data() {
    var _this = this;
    return {
      loading: false,
      dialogVisible: false,
      rules: {
        name: {
          required: true,
          trigger: 'blur',
          validator: function validator(rule, value, callback) {
            if (_this.manageType === 'modifyMulti') {
              callback();
            } else {
              if (!value) {
                callback(new Error('请输入标识'));
              } else {
                callback();
              }
            }
          }
        },
        fansList: {
          required: true,
          type: 'array',
          trigger: 'blur',
          validator: function validator(rule, value, callback) {
            var tips = _this.validateFansList(value);
            if (tips) {
              callback(new Error(tips));
            } else {
              callback();
            }
          }
        }
      },
      wechats: [],
      modifyMultiType: MULTI_TYPES.FANS_TAG,
      // 批量修改类型
      modifyMultiList: [{
        value: MULTI_TYPES.FANS_TAG,
        label: '批量修改导粉标签'
      }, {
        value: MULTI_TYPES.FANS_VALUE,
        label: '批量修改导粉组'
      }],
      // 批量选项是否存在已配置导粉标签的选项
      isMultiListHasTag: false,
      defaultFansTag: 1 // 默认标签，批量修改不可更改为默认标签
    };
  },
  computed: {
    // 弹窗标题
    manageTitle: function manageTitle() {
      var collection = {
        add: '新增',
        modify: '修改',
        modifyMulti: '批量操作'
      };
      return collection[this.manageType];
    },
    // 是否禁止修改导粉
    isFansDisabled: function isFansDisabled() {
      // 批量修改 & 批量修改导粉标签
      var disable1 = this.manageType === 'modifyMulti' && this.modifyMultiType == MULTI_TYPES.FANS_TAG;
      // 新增或修改 & 选择了标签且导粉标签不为默认标签的情况
      var disable2 = this.isAddOrModify && this.ruleForm.fansTag && this.ruleForm.fansTag !== this.defaultFansTag;
      // 批量修改 & 选择批量修改导粉组 & 批量选项中存在非默认导粉标签的选项
      var disable3 = this.manageType === 'modifyMulti' && this.modifyMultiType === MULTI_TYPES.FANS_VALUE && this.isMultiListHasTag;
      return disable1 || disable2 || disable3;
    },
    // 是否为新增或修改类型
    isAddOrModify: function isAddOrModify() {
      return this.manageType === 'add' || this.manageType === 'modify';
    },
    // 导粉总比例
    totalWeight: function totalWeight() {
      var fansList = this.ruleForm.fansList;
      if (!fansList || fansList.length === 0) {
        return 0;
      }
      var total = 0;
      var _iterator = _createForOfIteratorHelper(fansList),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          total += Number(item.weight);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      if (total > 100) {
        this.$message({
          message: '导粉号总比例已超过100%，请调整！',
          type: 'warning'
        });
      }
      return total;
    },
    // 是否显示导粉标签
    showFansTag: function showFansTag() {
      var isMultiModifyGroupValue = this.manageType === 'modifyMulti' && this.modifyMultiType === MULTI_TYPES.FANS_VALUE;
      return !isMultiModifyGroupValue;
    }
  },
  watch: {
    multiList: function multiList(value) {
      this.onModifyMultiTypeChange(this.modifyMultiType);
    }
  },
  mounted: function mounted() {
    this.getWxComponentList();
  },
  methods: {
    // 获取导粉组件列表
    getWxComponentList: function getWxComponentList() {
      var _this2 = this;
      var params = {
        per_page: 2000,
        page: 1,
        type: '1,2,3,5,6'
      };
      (0, _yunying.getWxList)(params).then(function (_ref) {
        var data = _ref.data,
          code = _ref.code;
        if (code === 200) {
          _this2.wechats = Object.freeze(data.list);
        }
      });
    },
    onCancel: function onCancel() {
      this.dialogVisible = false;
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this3.handleEvent();
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent() {
      var _this4 = this;
      var _this$ruleForm = this.ruleForm,
        id = _this$ruleForm.id,
        name = _this$ruleForm.name,
        fansList = _this$ruleForm.fansList,
        fansTag = _this$ruleForm.fansTag;
      var fansValues = [],
        fansWeights = [];
      fansList.forEach(function (element) {
        fansValues.push(element.value);
        fansWeights.push(element.weight);
      });
      var data = {
        name: name,
        wechat_group: fansValues.join(','),
        weight: fansWeights.join(',')
      };
      if (fansTag > 0) {
        data.tag_id = fansTag;
      }
      // 新增
      if (this.manageType === 'add') {
        (0, _yunying.addWxGroup)(data).then(function (_ref2) {
          var code = _ref2.code;
          if (code === 200) {
            _this4.$message({
              message: '添加成功',
              type: 'success'
            });
            _this4.dialogVisible = false;
            _this4.$parent.getWxConfigList(); // 更新父组件数据
          }
        });
        return;
      }
      // 修改
      if (this.manageType === 'modify') {
        (0, _yunying.modifyWxGroup)(id, data).then(function () {
          _this4.$message({
            message: '修改成功',
            type: 'success'
          });
          _this4.dialogVisible = false;
          _this4.$parent.getWxConfigList(); // 更新父组件数据
        });
        return;
      }
      // 批量修改
      if (this.manageType === 'modifyMulti') {
        this.modifyMulti({
          fansTag: fansTag,
          fansValues: fansValues,
          fansWeights: fansWeights
        });
        return;
      }
    },
    // 批量修改：批量修改选中的配置项
    modifyMulti: function modifyMulti(_ref3) {
      var _this5 = this;
      var fansTag = _ref3.fansTag,
        fansValues = _ref3.fansValues,
        fansWeights = _ref3.fansWeights;
      var ids = this.multiList.map(function (v) {
        return v.id;
      }).join(',');
      var params = {
        group_ids: ids
      };
      if (this.modifyMultiType === MULTI_TYPES.FANS_TAG) {
        params.tag_id = fansTag;
      } else {
        params.wechat_group = fansValues.join(',');
        params.weight = fansWeights.join(',');
      }
      (0, _wechats.modifyMultiWxGroup)(params).then(function (_ref4) {
        var code = _ref4.code;
        if (code === 200) {
          _this5.$message({
            message: '修改成功',
            type: 'success'
          });
          _this5.dialogVisible = false;
          _this5.$parent.getWxConfigList(); // 更新父组件数据
        }
      });
    },
    // 校验导粉配置
    validateFansList: function validateFansList() {
      var fansList = this.ruleForm.fansList;
      var tips = '';
      if (!fansList || fansList.length === 0) {
        tips = '请配置导粉号！';
      } else if (this.totalWeight != 100) {
        tips = '总比例需为100%，请调整！';
      } else {
        var seen = {};
        var _iterator2 = _createForOfIteratorHelper(fansList),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var item = _step2.value;
            if (!item.value) {
              tips = '请选择导粉号！';
              break;
            }
            // 判断是否重复
            if (seen[item.value]) {
              tips = '存在重复的导粉号配置，请检查修改！';
              break;
            }
            seen[item.value] = true;
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }
      return tips;
    },
    // 添加一行导粉配置
    addFans: function addFans() {
      var newItem = {
        value: '',
        weight: 0
      };
      this.ruleForm.fansList.push(newItem);
    },
    // 删除一行导粉配置
    deleteFans: function deleteFans(index) {
      this.ruleForm.fansList.splice(index, 1); // 移除数据
    },
    // 导粉标签变更处理
    onFansTagChange: function onFansTagChange(value) {
      // 找到对应导粉标签的导粉配置
      var findItem,
        fansList = [];
      for (var i = 0; i < this.fansTagList.length; i++) {
        var fansTagItem = this.fansTagList[i];
        if (fansTagItem.id === value) {
          findItem = fansTagItem;
          break;
        }
      }
      if (findItem) {
        var _findItem = findItem,
          weight = _findItem.weight,
          wechat_group = _findItem.wechat_group;
        var fansValues = [],
          fansWeights = [];
        if (wechat_group && weight) {
          fansValues = wechat_group.split(',');
          fansWeights = weight.split(',');
          fansList = fansValues.map(function (v, k) {
            return {
              value: Number(v),
              weight: Number(fansWeights[k])
            };
          });
        }
      }
      this.ruleForm.fansList = fansList;
    },
    // 批量修改类型变更处理
    onModifyMultiTypeChange: function onModifyMultiTypeChange(value) {
      var _this6 = this;
      // 批量修改导粉组，仅可批量修改未添加导粉标签的
      // 选中有导粉标签的不可修改导粉组
      if (value === MULTI_TYPES.FANS_VALUE) {
        // 判断是否存在已添加标签的数据项
        var hasTag = this.multiList.some(function (v) {
          return v.tag_id !== _this6.defaultFansTag;
        });
        this.isMultiListHasTag = hasTag;
        if (hasTag && this.dialogVisible) {
          this.$message({
            message: '批量选项存在非默认标签数据，请重新勾选！',
            type: 'warning'
          });
        }
        this.ruleForm.fansList = [];
      } else {
        this.onFansTagChange(this.ruleForm.fansTag);
      }
    }
  }
};