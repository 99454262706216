"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.addWxTag = addWxTag;
exports.getWxTagList = getWxTagList;
exports.modifyMultiWxGroup = modifyMultiWxGroup;
exports.modifyWxTag = modifyWxTag;
var _request = _interopRequireDefault(require("@/utils/request"));
/**
 * 资源库-导粉号模块相关接口
 */

/** 获取导粉标签列表 */
function getWxTagList(params) {
  return (0, _request.default)({
    url: '/resource/wx/tag',
    method: 'get',
    params: params
  });
}

/** 修改导粉标签 */
function modifyWxTag(id, data) {
  return (0, _request.default)({
    url: "/resource/wx/tag/".concat(id),
    method: 'post',
    data: data
  });
}

/** 新增导粉标签 */
function addWxTag(data) {
  return (0, _request.default)({
    url: '/resource/wx/tag',
    method: 'post',
    data: data
  });
}

/** 批量修改导粉组 */
function modifyMultiWxGroup(data) {
  return (0, _request.default)({
    url: '/resource/wx/multi/group',
    method: 'post',
    data: data
  });
}