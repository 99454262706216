var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.manageTitle,
            visible: _vm.dialogVisible,
            width: "750px",
            center: "",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "section",
            { staticClass: "lists" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-position": "left",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.manageType == "modifyMulti",
                          expression: "manageType == 'modifyMulti'",
                        },
                      ],
                      attrs: { label: "操作类型", prop: "modifyMultiType" },
                    },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { input: _vm.onModifyMultiTypeChange },
                          model: {
                            value: _vm.modifyMultiType,
                            callback: function ($$v) {
                              _vm.modifyMultiType = $$v
                            },
                            expression: "modifyMultiType",
                          },
                        },
                        _vm._l(_vm.modifyMultiList, function (item, index) {
                          return _c(
                            "el-radio",
                            { key: index, attrs: { label: item.value } },
                            [
                              _vm._v(
                                "\n                            " +
                                  _vm._s(item.label) +
                                  "\n                        "
                              ),
                            ]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showFansTag,
                          expression: "showFansTag",
                        },
                      ],
                      attrs: { label: "导粉标签", prop: "fansTag" },
                    },
                    [
                      _c(
                        "el-select",
                        {
                          attrs: { placeholder: "请选择导粉标签" },
                          on: { change: _vm.onFansTagChange },
                          model: {
                            value: _vm.ruleForm.fansTag,
                            callback: function ($$v) {
                              _vm.$set(_vm.ruleForm, "fansTag", $$v)
                            },
                            expression: "ruleForm.fansTag",
                          },
                        },
                        _vm._l(_vm.fansTagList, function (item) {
                          return _c("el-option", {
                            key: item.id,
                            attrs: {
                              label: item.title,
                              value: item.id,
                              disabled:
                                item.id === _vm.defaultFansTag &&
                                _vm.manageType === "modifyMulti",
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.isAddOrModify,
                          expression: "isAddOrModify",
                        },
                      ],
                      attrs: { label: "标识", prop: "name" },
                    },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "投放-测试1" },
                        model: {
                          value: _vm.ruleForm.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "name", $$v)
                          },
                          expression: "ruleForm.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "导粉号", prop: "fansList" } },
                    [
                      _c(
                        "el-card",
                        {
                          attrs: {
                            "body-style": "background: #f7f8fa",
                            shadow: "never",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { "padding-bottom": "20px" } },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    type: "primary",
                                    icon: "el-icon-plus",
                                    disabled: _vm.isFansDisabled,
                                  },
                                  on: { click: _vm.addFans },
                                },
                                [
                                  _vm._v(
                                    "\n                                添加导粉号\n                            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "span",
                                { staticStyle: { "padding-left": "20px" } },
                                [
                                  _vm._v(
                                    "总比例：" + _vm._s(_vm.totalWeight) + "%"
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm._l(
                            _vm.ruleForm.fansList,
                            function (fansItem, fansIndex) {
                              return _c(
                                "el-form",
                                {
                                  key: fansItem.id,
                                  staticStyle: { "margin-bottom": "10px" },
                                  attrs: { inline: true },
                                },
                                [
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-select",
                                        {
                                          staticClass: "width-300",
                                          attrs: {
                                            placeholder: "请选择导粉号",
                                            filterable: "",
                                            disabled: _vm.isFansDisabled,
                                          },
                                          model: {
                                            value: fansItem.value,
                                            callback: function ($$v) {
                                              _vm.$set(fansItem, "value", $$v)
                                            },
                                            expression: "fansItem.value",
                                          },
                                        },
                                        _vm._l(_vm.wechats, function (v, k) {
                                          return _c("el-option", {
                                            key: k,
                                            staticClass: "max-width-500",
                                            attrs: {
                                              label:
                                                v.qrcode_id +
                                                " " +
                                                v.name +
                                                (v.wechat
                                                  ? ": " + v.wechat
                                                  : "") +
                                                " [" +
                                                _vm.FANS_TYPES_MAP.get(v.type) +
                                                "]",
                                              value: v.id,
                                              disabled:
                                                v.status === 1 ? false : true,
                                            },
                                          })
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    { attrs: { label: "比例" } },
                                    [
                                      _c("el-input-number", {
                                        staticClass: "width-150",
                                        attrs: {
                                          min: 0,
                                          max: 100,
                                          precision: 0,
                                          label: "比例",
                                          disabled: _vm.isFansDisabled,
                                        },
                                        model: {
                                          value: fansItem.weight,
                                          callback: function ($$v) {
                                            _vm.$set(fansItem, "weight", $$v)
                                          },
                                          expression: "fansItem.weight",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "el-form-item",
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            type: "text",
                                            disabled: _vm.isFansDisabled,
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deleteFans(fansIndex)
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                                    删除\n                                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "span",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c("el-button", { on: { click: _vm.onCancel } }, [
                _vm._v("取消"),
              ]),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function ($event) {
                      return _vm.submitForm("ruleForm")
                    },
                  },
                },
                [_vm._v("确定并同步")]
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }