var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "section",
        { staticClass: "search-area" },
        [
          _c(
            "el-select",
            {
              staticClass: "width-200",
              attrs: { placeholder: "导粉号类型", clearable: "", multiple: "" },
              on: { change: _vm.handleType },
              model: {
                value: _vm.typesVal,
                callback: function ($$v) {
                  _vm.typesVal = $$v
                },
                expression: "typesVal",
              },
            },
            _vm._l(_vm.types, function (item) {
              return _c("el-option", {
                key: item.value,
                attrs: { label: item.label, value: item.value },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "width-200",
              attrs: {
                filterable: "",
                multiple: "",
                placeholder: "部门",
                clearable: "",
              },
              on: {
                change: function (val) {
                  _vm.handleType(val, "department")
                },
              },
              model: {
                value: _vm.departmentVal,
                callback: function ($$v) {
                  _vm.departmentVal = $$v
                },
                expression: "departmentVal",
              },
            },
            _vm._l(
              _vm.wechatConstantData["department"],
              function (item, index) {
                return _c("el-option", {
                  key: index,
                  attrs: { label: item, value: index },
                })
              }
            ),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "width-200",
              attrs: {
                filterable: "",
                placeholder: "主体",
                clearable: "",
                multiple: "",
              },
              on: {
                change: function (val) {
                  _vm.handleType(val, "subject")
                },
              },
              model: {
                value: _vm.subjectVal,
                callback: function ($$v) {
                  _vm.subjectVal = $$v
                },
                expression: "subjectVal",
              },
            },
            _vm._l(_vm.wechatConstantData["subject"], function (item, index) {
              return _c("el-option", {
                key: index,
                attrs: { label: item, value: index },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "width-200",
              attrs: { placeholder: "状态", clearable: "" },
              on: {
                change: function (val) {
                  _vm.handleType(val, "status")
                },
              },
              model: {
                value: _vm.statusVal,
                callback: function ($$v) {
                  _vm.statusVal = $$v
                },
                expression: "statusVal",
              },
            },
            _vm._l(_vm.statusData, function (item) {
              return _c("el-option", {
                key: item.type,
                attrs: { label: item.val, value: item.type },
              })
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "width-250",
              attrs: { placeholder: "活码标识（中文）", clearable: "" },
              on: { change: _vm.onSearch },
              model: {
                value: _vm.qrcodeRemarks,
                callback: function ($$v) {
                  _vm.qrcodeRemarks = $$v
                },
                expression: "qrcodeRemarks",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "width-250",
              attrs: { placeholder: "活码标识（拼音）", clearable: "" },
              on: { change: _vm.onSearch },
              model: {
                value: _vm.qrcodeSign,
                callback: function ($$v) {
                  _vm.qrcodeSign = $$v
                },
                expression: "qrcodeSign",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-input",
            {
              staticClass: "width-250",
              attrs: { placeholder: "活码ID", clearable: "" },
              on: { change: _vm.onSearch },
              model: {
                value: _vm.qrcodeId,
                callback: function ($$v) {
                  _vm.qrcodeId = $$v
                },
                expression: "qrcodeId",
              },
            },
            [
              _c("el-button", {
                attrs: { slot: "append", icon: "el-icon-search" },
                slot: "append",
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "btn-box" },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("新增")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.updateGuideDisposition },
                },
                [_vm._v("更新：" + _vm._s(_vm.updateTime))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", plain: "" },
                  on: {
                    click: function ($event) {
                      return _vm.onTableHandle(_vm.tableExpand)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n                " +
                      _vm._s(
                        _vm.tableExpand == "0"
                          ? "展开表格所有行"
                          : "收起表格所有行"
                      ) +
                      "\n            "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.tableData,
            border: "",
            size: "medium",
            "expand-row-keys": _vm.expandsDefault,
            "row-key": _vm.getRowKeys,
          },
        },
        [
          _c("el-table-column", {
            attrs: { type: "expand" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-table",
                      {
                        staticStyle: { width: "100%" },
                        attrs: { data: scope.row.config },
                      },
                      [
                        _c("el-table-column", {
                          attrs: {
                            prop: "group_id",
                            label: "分组ID",
                            "min-width": "70",
                            "header-align": "center",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(scope.row.group.group_id) +
                                          "\n                            "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "name",
                            label: "分组名称",
                            "min-width": "70",
                            "header-align": "center",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        "\n                                " +
                                          _vm._s(scope.row.group.name) +
                                          "\n                            "
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "flag",
                            label: "标识flag",
                            "min-width": "70",
                            "header-align": "center",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.page_config,
                                    function (v, k) {
                                      return _c(
                                        "div",
                                        {
                                          key: k,
                                          class: _vm.tableRowClassName(k),
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(v.flag) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    }
                                  )
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                        _vm._v(" "),
                        _c("el-table-column", {
                          attrs: {
                            prop: "app_id",
                            label: "app_id",
                            "min-width": "70",
                            "header-align": "center",
                            align: "center",
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function (scope) {
                                  return _vm._l(
                                    scope.row.page_config,
                                    function (v, k) {
                                      return _c(
                                        "div",
                                        {
                                          key: k,
                                          class: _vm.tableRowClassName(k),
                                        },
                                        [
                                          _vm._v(
                                            "\n                                " +
                                              _vm._s(v.app_id) +
                                              "\n                            "
                                          ),
                                        ]
                                      )
                                    }
                                  )
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              "header-align": "center",
              "min-width": "30",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "qrcode_remarks ｜ name",
              label: "活码标识（中文）",
              "min-width": "60",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(scope.row.qrcode_remarks || "/") +
                        "\n            "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "tps",
              label: "导粉号类型",
              "min-width": "60",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "qrcode_sign",
              label: "活码标识（拼音）",
              "min-width": "60",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(scope.row.qrcode_sign || "/") +
                        "\n            "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "qrcode_id",
              label: "活码ID",
              "min-width": "60",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _vm._v(
                      "\n                " +
                        _vm._s(scope.row.qrcode_id || "/") +
                        "\n            "
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "qrcode",
              label: "二维码/获客链接/链接",
              "min-width": "60",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "div",
                      [
                        scope.row.qrcode
                          ? _c(
                              "el-button",
                              {
                                staticClass: "qrcode-btn",
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.onPreviewImage(scope.row.qrcode)
                                  },
                                },
                              },
                              [_vm._v("二维码预览")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c(
                          "p",
                          [
                            scope.row.qrcode
                              ? _c(
                                  "el-link",
                                  {
                                    directives: [
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:copy",
                                        value: scope.row.qrcode,
                                        expression: "scope.row.qrcode",
                                        arg: "copy",
                                      },
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:success",
                                        value: _vm.onCopy,
                                        expression: "onCopy",
                                        arg: "success",
                                      },
                                      {
                                        name: "clipboard",
                                        rawName: "v-clipboard:error",
                                        value: _vm.onError,
                                        expression: "onError",
                                        arg: "error",
                                      },
                                    ],
                                    attrs: { type: "primary" },
                                  },
                                  [
                                    _vm._v(
                                      "\n                            复制二维码\n                        "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "p",
                          [
                            (scope.row.type === 3 || scope.row.type === 6) &&
                            scope.row.qrlink
                              ? _c(
                                  "el-tooltip",
                                  {
                                    attrs: {
                                      placement: "top",
                                      content: scope.row.qrlink,
                                    },
                                  },
                                  [
                                    _c(
                                      "el-link",
                                      {
                                        directives: [
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:copy",
                                            value: scope.row.qrlink,
                                            expression: "scope.row.qrlink",
                                            arg: "copy",
                                          },
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:success",
                                            value: _vm.onCopy,
                                            expression: "onCopy",
                                            arg: "success",
                                          },
                                          {
                                            name: "clipboard",
                                            rawName: "v-clipboard:error",
                                            value: _vm.onError,
                                            expression: "onError",
                                            arg: "error",
                                          },
                                        ],
                                        attrs: { type: "primary" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                                " +
                                            _vm._s(
                                              scope.row.type === 3
                                                ? "复制获客链接"
                                                : "复制链接"
                                            ) +
                                            "\n                            "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "status",
              label: "状态",
              "min-width": "30",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.status === 1
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "success",
                              effect: "dark",
                              size: "mini",
                            },
                          },
                          [_vm._v("可用\n                ")]
                        )
                      : _c(
                          "el-tag",
                          {
                            attrs: {
                              type: "danger",
                              effect: "dark",
                              size: "mini",
                            },
                          },
                          [_vm._v("禁用")]
                        ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "department",
              label: "部门",
              "min-width": "60",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.wechatConstantData["department"][
                              scope.row.department
                            ]
                          ) +
                          "\n                "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "subject",
              label: "主体",
              "min-width": "60",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("div", [
                      _vm._v(
                        "\n                    " +
                          _vm._s(
                            _vm.wechatConstantData["subject"][scope.row.subject]
                          ) +
                          "\n                "
                      ),
                    ]),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "updated_at",
              label: "最近同步时间",
              "min-width": "60",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "cancel",
              label: "修改",
              "min-width": "30",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c("el-button", {
                      attrs: { type: "text", icon: "el-icon-edit" },
                      on: {
                        click: function ($event) {
                          return _vm.onModify(scope.row)
                        },
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.totalPage > 0
        ? _c(
            "section",
            { staticClass: "pagination-wrap" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-size": _vm.perPage,
                  layout: "prev, pager, next, jumper",
                  "page-count": _vm.totalPage,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    _vm.currentPage = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.currentPage = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("Manage", {
        ref: "managePop",
        attrs: {
          manageType: _vm.manageType,
          ruleForm: _vm.chooseData,
          imageUploadData: _vm.imageUploadData,
          type: _vm.types,
          wechatConstantData: _vm.wechatConstantData,
        },
        on: {
          "update:ruleForm": function ($event) {
            _vm.chooseData = $event
          },
          "update:rule-form": function ($event) {
            _vm.chooseData = $event
          },
          "update:imageUploadData": function ($event) {
            _vm.imageUploadData = $event
          },
          "update:image-upload-data": function ($event) {
            _vm.imageUploadData = $event
          },
          "update:wechatConstantData": function ($event) {
            _vm.wechatConstantData = $event
          },
          "update:wechat-constant-data": function ($event) {
            _vm.wechatConstantData = $event
          },
        },
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "图片预览",
            visible: _vm.dialogVisible,
            width: "30%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [_c("el-image", { attrs: { fit: "contain", src: _vm.previewImage } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }