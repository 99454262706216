"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.number.constructor");
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.object.freeze");
var _wechats = require("@/api/wechats");
var _constants = require("@/constants");
var _fansTagManager = _interopRequireDefault(require("./components/fans-tag-manager.vue"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = exports.default = {
  components: {
    FansTagManager: _fansTagManager.default
  },
  data: function data() {
    return {
      loading: true,
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      perPage: 15,
      searchName: '',
      chooseData: {},
      manageType: 'add',
      fansTag: '',
      styleList: ['', 'success', 'warning', 'danger'],
      colorList: ['blue', 'green', 'yellow', 'red']
    };
  },
  mounted: function mounted() {
    this.getList();
  },
  methods: {
    // 获取列表
    getList: function getList() {
      var _this = this;
      var params = {
        page: this.currentPage,
        per_page: this.perPage
      };
      if (this.searchName) {
        params.title = this.searchName;
      }
      (0, _wechats.getWxTagList)(params).then(function (_ref) {
        var code = _ref.code,
          data = _ref.data;
        if (code === 200) {
          _this.loading = false;
          var list = data.list,
            total_page = data.total_page;
          _this.tableData = Object.freeze(list);
          _this.totalPage = total_page;
        }
      });
    },
    onSearch: function onSearch() {
      this.loading = true;
      // 搜索重置
      this.totalPage = 1;
      this.currentPage = 1;
      this.getList();
    },
    onModify: function onModify(val) {
      var id = val.id,
        title = val.title,
        wechat_group = val.wechat_group,
        weight = val.weight;
      var fansValues = [],
        fansWeights = [],
        fansList = [];
      if (wechat_group && weight) {
        fansValues = wechat_group.split(',');
        fansWeights = weight.split(',');
        fansList = fansValues.map(function (v, k) {
          return {
            value: Number(v),
            weight: Number(fansWeights[k])
          };
        });
      }
      this.chooseData = {
        id: id,
        title: title,
        fansList: fansList
      };
      this.manageType = 'modify';
      this.$refs.managePop.dialogVisible = true;
    },
    onAdd: function onAdd() {
      this.manageType = 'add';
      this.chooseData = {
        title: '',
        fansList: []
      };
      this.$refs.managePop.dialogVisible = true;
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.loading = true;
      this.getList();
    },
    groupFormat: function groupFormat(_ref2) {
      var wechat_name = _ref2.wechat_name,
        wechat = _ref2.wechat,
        type = _ref2.type;
      var tps = _constants.FANS_TYPES_MAP.get(type);
      return "".concat(wechat_name, "\uFF08").concat(tps).concat(wechat ? '：' + wechat : '', "\uFF09");
    }
  }
};