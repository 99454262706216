"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.regexp.to-string");
require("core-js/modules/es7.object.get-own-property-descriptors");
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.object.keys");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _defineProperty2 = _interopRequireDefault(require("/var/www/html/node_modules/@babel/runtime-corejs2/helpers/defineProperty.js"));
require("core-js/modules/es6.number.constructor");
var _pattern = require("@/api/pattern");
var _vuex = require("vuex");
var _lodash = _interopRequireDefault(require("lodash"));
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    // 操作类型
    manageType: {
      type: String,
      default: ''
    },
    ruleForm: {
      type: Object,
      default: function _default() {
        return {
          value: '',
          // 配置值
          channel_id: '',
          // 渠道
          product_id: '',
          // 产品ID ， 所有产品则为：all
          mode: '',
          // 模式：0-模糊匹配  1-精准匹配
          class: '' // 配置类型
        };
      }
    },
    // 模型列表
    patternList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 多选列表
    multiList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 配置类型列表
    configTypeList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 修改状态列表
    changeStateList: {
      type: Array,
      default: function _default() {
        return [];
      }
    },
    // 禁止修改的标识
    forbidModifyFlag: {
      type: Number,
      default: 1
    }
  },
  data: function data() {
    return {
      dialogVisible: false,
      rules: {
        channel_id: {
          required: true,
          message: '请输入渠道',
          trigger: 'blur'
        },
        product_id: {
          required: true,
          message: '请输入产品ID',
          trigger: 'blur'
        },
        mode: {
          required: true,
          message: '请选择渠道匹配模式',
          trigger: 'blur'
        },
        class: {
          required: true,
          message: '请选择配置类型',
          trigger: 'blur'
        }
      },
      ratioList: [],
      canClick: true,
      // 是否可以点击提交按钮
      changeState: 0,
      // 0 可修改，1 禁止修改，默认可修改
      modifyMultiType: 'configType',
      // 批量修改类型 configType-配置类型，configValue-配置值
      modifyMultiList: [{
        value: 'configType',
        label: '批量修改配置类型'
      }, {
        value: 'configValue',
        label: '批量修改配置值'
      }, {
        value: 'delete',
        label: '批量删除'
      }],
      // 可复用的初始化配置值列表
      initRatioList: [{
        value: '',
        label: '',
        weight: 0
      }]
    };
  },
  computed: _objectSpread(_objectSpread({}, (0, _vuex.mapState)({
    all_products: function all_products(state) {
      var products = _lodash.default.cloneDeep(state.common.all_products);
      var item = {
        id: 'all',
        name: '全部测算'
      };
      if (products) {
        products.unshift(item);
      }
      return products;
    }
  })), {}, {
    // 总比例
    totalRatio: function totalRatio() {
      if (!this.ratioList || this.ratioList.length === 0) {
        return 0;
      }
      var total = 0;
      var _iterator = _createForOfIteratorHelper(this.ratioList),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          total += Number(item.weight);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      if (total > 100) {
        this.$message({
          message: '总比例已超过100%，请调整！',
          type: 'warning'
        });
      }
      return total;
    },
    // 弹窗标题
    manageTitle: function manageTitle() {
      var titleObj = {
        add: '新增',
        modify: '修改',
        modifyMulti: '批量操作',
        modifyAll: '一键配置'
      };
      return titleObj[this.manageType];
    },
    /**
     * 是否禁用提交按钮
     * 禁用情况：
     * 1. 批量操作 & 操作类型不为批量修改类型 & 存在不可修改或删除项
     * 2. 一键配置 & 选了第一项
     */
    isSubmitButtonDisabled: function isSubmitButtonDisabled() {
      var _this = this;
      var isMultiModify = this.manageType === 'modifyMulti';
      var isNotModifyConfigType = this.modifyMultiType != 'configType';
      var hasForbiddenItem = this.multiList.some(function (item) {
        return item.change_state === _this.forbidModifyFlag;
      });
      var disable1 = isMultiModify && isNotModifyConfigType && hasForbiddenItem;
      var disable2 = this.manageType === 'modifyAll' && this.ruleForm.class === 0;
      return disable1 || disable2;
    },
    /**
     * 是否禁止修改配置值
     * 禁止的情况：
     * 1.非一键配置 & 当前修改状态为不可修改
     * 2.批量修改&选择配配置类型
     * 3.一键配置 & 选了第一项
     */
    isConfigDisabled: function isConfigDisabled() {
      // 非一键配置 & 当前修改状态为不可修改
      var disable1 = this.manageType !== 'modifyAll' && this.changeState === this.forbidModifyFlag;
      // 批量操作 & 批量修改配置类型
      var disable2 = this.manageType === 'modifyMulti' && this.modifyMultiType === 'configType';
      var disable3 = this.manageType === 'modifyAll' && this.ruleForm.class === 0;
      return disable1 || disable2 || disable3;
    },
    /**
     * 是否显示配置值项
     * 显示的情况：
     * 1.新增、修改；
     * 2.批量操作 & (配置类型有值 || 批量修改配置值)
     * 3.一键修改 & 选择了配置类型
     */
    showConfigValue: function showConfigValue() {
      // 批量修改 & (配置类型有值 || 批量修改配置值)
      var show1 = this.manageType === 'modifyMulti' && (this.ruleForm.class >= 0 || this.modifyMultiType === 'configValue');
      // 一键修改 & 选择了配置类型
      var show2 = this.manageType === 'modifyAll' && this.ruleForm.class >= 0;
      return this.isAddOrModify || show1 || show2;
    },
    /**
     * 是否显示配置类型项
     * 显示的情况：
     * 1.新增|修改
     * 2.一键配置
     * 3.批量操作 & 批量修改配置类型
     */
    showConfigType: function showConfigType() {
      // 一键配置
      var show1 = this.manageType === 'modifyAll';
      // 批量修改&修改配置类选型
      var show2 = this.manageType === 'modifyMulti' && this.modifyMultiType === 'configType';
      return this.isAddOrModify || show1 || show2;
    },
    // 是否为新增或修改类型
    isAddOrModify: function isAddOrModify() {
      return this.manageType === 'add' || this.manageType === 'modify';
    }
  }),
  watch: {
    // 表单变更
    ruleForm: function ruleForm(value, oldValue) {
      if (value && this.manageType === 'modify') {
        this.ratioList = _lodash.default.cloneDeep(value.newValue);
      }
    },
    // 管理类型变更
    manageType: function manageType(value, oldValue) {
      if (value === 'add' || value === 'modifyMulti') {
        this.ratioList = _lodash.default.cloneDeep(this.initRatioList);
      }
    },
    // 批量修改类型变更
    modifyMultiType: function modifyMultiType(value, oldValue) {
      this.onModifyMultiTypeChange(value);
    },
    dialogVisible: function dialogVisible(value, oldValue) {
      // 每次打开弹窗，重新渲染
      if (value) {
        this.changeState = 0;
      }
    }
  },
  methods: {
    onCancel: function onCancel() {
      this.dialogVisible = false;
    },
    submitForm: function submitForm(formName) {
      var _this2 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          var isDelete = _this2.manageType === 'modifyMulti' && _this2.modifyMultiType == 'delete'; // 是否为批量删除操作
          var valueObj = {};
          // 批量删除操作不检查配置
          if (!isDelete) {
            if (_this2.ratioList.length === 0) {
              _this2.$message({
                message: '请配置模型比例！',
                type: 'warning'
              });
              return;
            }
            if (_this2.totalRatio != 100) {
              _this2.$message({
                message: '总比例需为100%，请调整！',
                type: 'warning'
              });
              return;
            }
            // 权重处理
            var _iterator2 = _createForOfIteratorHelper(_this2.ratioList),
              _step2;
            try {
              for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
                var item = _step2.value;
                if (item.value === '') {
                  _this2.$message({
                    message: '请选择模型！',
                    type: 'warning'
                  });
                  return;
                }
                if (valueObj[item.value]) {
                  _this2.$message({
                    message: '存在重复模型配置，请检查并修改！',
                    type: 'warning'
                  });
                  return;
                }
                valueObj[item.value] = item.weight;
              }
            } catch (err) {
              _iterator2.e(err);
            } finally {
              _iterator2.f();
            }
          }
          _this2.handleEvent(valueObj);
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent(valueObj) {
      if (!this.canClick) {
        this.$message({
          message: '请勿重复提交！',
          type: 'warning'
        });
        return;
      }
      this.canClick = false;
      var value = JSON.stringify(valueObj);
      if (this.manageType === 'modifyAll') {
        // 一键配置
        this.editOneButton(value);
      } else if (this.manageType === 'modifyMulti') {
        // 批量修改/删除
        this.editMulti(value);
      } else {
        var _this$ruleForm = this.ruleForm,
          id = _this$ruleForm.id,
          channel_id = _this$ruleForm.channel_id,
          product_id = _this$ruleForm.product_id,
          mode = _this$ruleForm.mode;
        var params = {
          content: value,
          channel_id: channel_id,
          product_id: product_id,
          mode: mode,
          class: this.ruleForm.class,
          change_state: this.changeState
        };
        if (this.manageType === 'add') {
          // 新增
          this.add(params);
        } else {
          // 修改
          this.edit(id, params);
        }
      }
    },
    // 一键配置
    editOneButton: function editOneButton(value) {
      var _this3 = this;
      var params = {
        change_state: this.changeState,
        content: value,
        class: this.ruleForm.class
      };
      (0, _pattern.editProductsPatternByOneButton)(params).then(function (_ref) {
        var code = _ref.code;
        if (code === 200) {
          _this3.$message({
            message: '修改成功',
            type: 'success'
          });
          _this3.dialogVisible = false;
          _this3.$parent.getConfigTypeList(); // 更新配置类型
          _this3.$parent.getList(); // 更新列表
          _this3.canClick = true;
        }
      });
    },
    // 批量修改/删除
    editMulti: function editMulti(value) {
      var _this4 = this;
      var idList = this.multiList.map(function (item) {
        return item.id;
      });
      var params = {
        ids: idList.join(',')
      };
      var isDelete = this.modifyMultiType === 'delete';
      if (!isDelete) {
        params.content = value;
      }
      if (this.modifyMultiType === 'configType') {
        params.class = this.ruleForm.class;
      }
      var multiFuc = isDelete ? _pattern.deleteMultiProductPattern : _pattern.editMultiProductPattern;
      var message = isDelete ? '删除成功' : '修改成功';
      multiFuc(params).then(function (_ref2) {
        var code = _ref2.code;
        if (code === 200) {
          _this4.$message({
            message: message,
            type: 'success'
          });
          _this4.dialogVisible = false;
          _this4.$parent.getConfigTypeList(); // 更新配置类型
          _this4.$parent.getList(); // 更新列表
          _this4.canClick = true;
        }
      }).catch(function (err) {
        _this4.canClick = true;
      });
    },
    // 修改
    edit: function edit(id, params) {
      var _this5 = this;
      (0, _pattern.editProductPattern)(id, params).then(function (_ref3) {
        var code = _ref3.code;
        if (code === 200) {
          _this5.$message({
            message: '修改成功',
            type: 'success'
          });
          _this5.dialogVisible = false;
          _this5.$parent.getConfigTypeList(); // 更新配置类型
          _this5.$parent.getList(); // 更新列表
          _this5.canClick = true;
        }
      }).catch(function (err) {
        _this5.canClick = true;
      });
    },
    // 新增
    add: function add(params) {
      var _this6 = this;
      (0, _pattern.addProductPattern)(params).then(function (_ref4) {
        var code = _ref4.code;
        if (code === 200) {
          _this6.$message({
            message: '添加成功',
            type: 'success'
          });
          _this6.dialogVisible = false;
          _this6.$parent.getConfigTypeList(); // 更新配置类型
          _this6.$parent.getList(); // 更新列表
          _this6.canClick = true;
        }
      }).catch(function (err) {
        _this6.canClick = true;
      });
    },
    // 删除一行模型配置
    deletePattern: function deletePattern(index) {
      this.ratioList.splice(index, 1); // 移除数据
    },
    // 添加一行模型配置
    addPattern: function addPattern() {
      var newItem = _objectSpread({}, this.initRatioList[0]);
      this.ratioList.push(newItem);
    },
    // 配置类型变更处理（使用对应类型的配置值填充数据）
    onConfigTypeChange: function onConfigTypeChange(val) {
      var item = this.findConfigTypeItem(this.ruleForm.class);
      // 一键配置 & 可修改的情况清空配置
      var isClearConfig = this.manageType === 'modifyAll' && item.changeState !== this.forbidModifyFlag;
      // 更新修改状态值
      this.changeState = item.changeState;
      if (isClearConfig) {
        this.ratioList = _lodash.default.cloneDeep(this.initRatioList);
      } else {
        this.ratioList = item.ratioList;
      }
    },
    // 批量修改类型变更处理
    onModifyMultiTypeChange: function onModifyMultiTypeChange(value) {
      var _this7 = this;
      if (value === 'configType') {
        // 切换批量配置类型时，用对应的配置类型的配置值填充配置值项
        var item = this.findConfigTypeItem(this.ruleForm.class);
        this.ratioList = item.ratioList;
        this.changeState = item.changeState;
      } else {
        // 切换批量配置值/批量删除时，判断所选列表是否存在不可修改/删除项，若存在则提示，并且禁止修改
        var hasForbiddenItem = this.multiList.some(function (item) {
          return item.change_state === _this7.forbidModifyFlag;
        });
        if (hasForbiddenItem) {
          this.changeState = 1;
          this.$message({
            message: '批量选项中有禁止修改/删除数据，请重新勾选！',
            type: 'warning'
          });
        } else {
          this.changeState = 0;
        }
        if (value === 'configValue') {
          // 初始化配置值列表
          this.ratioList = _lodash.default.cloneDeep(this.initRatioList);
        }
      }
    },
    // 找到对应的配置类型项
    findConfigTypeItem: function findConfigTypeItem(configType) {
      var configTypeItem = [];
      for (var i = 0; i < this.configTypeList.length; i++) {
        var item = this.configTypeList[i];
        if (item.value === configType) {
          configTypeItem = item;
          break;
        }
      }
      return configTypeItem;
    }
  }
};