var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-form",
        { attrs: { inline: true } },
        [
          _c(
            "el-form-item",
            [
              _c(
                "el-input",
                {
                  staticClass: "width-200",
                  attrs: { placeholder: "标识名搜索", clearable: "" },
                  on: { change: _vm.onSearch },
                  model: {
                    value: _vm.searchName,
                    callback: function ($$v) {
                      _vm.searchName = $$v
                    },
                    expression: "searchName",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-input",
                {
                  staticClass: "width-200",
                  attrs: { placeholder: "导粉号搜索", clearable: "" },
                  on: { change: _vm.onSearch },
                  model: {
                    value: _vm.searchId,
                    callback: function ($$v) {
                      _vm.searchId = $$v
                    },
                    expression: "searchId",
                  },
                },
                [
                  _c("el-button", {
                    attrs: { slot: "append", icon: "el-icon-search" },
                    slot: "append",
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-select",
                {
                  staticClass: "width-250",
                  attrs: { placeholder: "导粉标签", clearable: "" },
                  on: { change: _vm.onSearch },
                  model: {
                    value: _vm.searchTagTitle,
                    callback: function ($$v) {
                      _vm.searchTagTitle = $$v
                    },
                    expression: "searchTagTitle",
                  },
                },
                _vm._l(_vm.fansTagList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.title, value: item.title },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", icon: "el-icon-plus" },
                  on: { click: _vm.onAdd },
                },
                [_vm._v("新增")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            [
              _c(
                "el-popover",
                {
                  attrs: {
                    placement: "top-start",
                    width: "200",
                    trigger: "hover",
                    content: "提示：批量操作仅对当前页勾选项有效！",
                  },
                },
                [
                  _c(
                    "el-button",
                    {
                      attrs: {
                        slot: "reference",
                        type: "danger",
                        icon: "el-icon-edit",
                        plain: "",
                      },
                      on: { click: _vm.onModifyMulti },
                      slot: "reference",
                    },
                    [_vm._v("\n                    批量操作\n                ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticStyle: { width: "100%", "min-height": "60vh" },
          attrs: {
            "element-loading-text": "加载中...",
            "element-loading-background": "rgba(0, 0, 0, 0.3)",
            data: _vm.tableData,
            border: "",
            size: "medium",
          },
          on: { "selection-change": _vm.handleSelectionChange },
        },
        [
          _c("el-table-column", { attrs: { type: "selection", width: "40" } }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              type: "index",
              label: "序号",
              width: "70",
              "header-align": "center",
              align: "center",
              fixed: "",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "name",
              label: "导粉组（标识）",
              width: "160",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "wechat_group_info",
              label: "导粉组详情",
              "min-width": "500",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.wechat_group_info
                      ? _c(
                          "div",
                          _vm._l(scope.row.wechat_group_info, function (v, k) {
                            return _c(
                              "div",
                              {
                                key: k,
                                class:
                                  v.status === 1
                                    ? _vm.colorList[k % _vm.colorList.length]
                                    : "gray",
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.groupFormat(v)) +
                                    "\n                        "
                                ),
                                scope.row.weight
                                  ? _c(
                                      "i",
                                      { staticClass: "fans-percentage" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              "比例：" +
                                                scope.row.weight.split(",")[k] +
                                                "%"
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          0
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "tag_id",
              label: "导粉标签",
              align: "center",
              width: "160",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.tag_id
                      ? _c(
                          "el-tag",
                          {
                            attrs: {
                              type: _vm.styleList[
                                (scope.row.tag_id % _vm.styleList.length) - 1
                              ],
                              effect: "plain",
                            },
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(
                                  _vm.getFansTagNameById(scope.row.tag_id)
                                ) +
                                "\n                "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "cus_acq_group_info",
              label: "获客链接详情",
              "min-width": "300",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    scope.row.cus_acq_group_info
                      ? _c(
                          "div",
                          _vm._l(scope.row.cus_acq_group_info, function (v, k) {
                            return _c(
                              "el-tag",
                              {
                                key: k,
                                staticClass: "group-tag",
                                attrs: {
                                  effect: v.status === 1 ? "light" : "dark",
                                  type:
                                    v.status === 1
                                      ? _vm.styleList[k % _vm.styleList.length]
                                      : "info",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        " +
                                    _vm._s(_vm.groupFormat(v)) +
                                    "\n                        "
                                ),
                                scope.row.cus_acq_weight
                                  ? _c(
                                      "i",
                                      { staticClass: "fans-percentage" },
                                      [
                                        _vm._v(
                                          "\n                            " +
                                            _vm._s(
                                              "比例：" +
                                                scope.row.cus_acq_weight.split(
                                                  ","
                                                )[k] +
                                                "%"
                                            ) +
                                            "\n                        "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ]
                            )
                          }),
                          1
                        )
                      : _vm._e(),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "flag",
              label: "标识flag",
              width: "160",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.page_config, function (v, k) {
                    return _c(
                      "el-tag",
                      {
                        key: k,
                        staticClass: "group-tag",
                        attrs: {
                          type: _vm.styleList[k % _vm.styleList.length],
                          effect: "plain",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(v.flag) +
                            "\n                "
                        ),
                      ]
                    )
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "app_id",
              label: "app_id",
              width: "120",
              "header-align": "center",
              align: "center",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return _vm._l(scope.row.page_config, function (v, k) {
                    return _c(
                      "el-tag",
                      {
                        key: k,
                        staticClass: "group-tag",
                        attrs: {
                          type: _vm.styleList[k % _vm.styleList.length],
                          effect: "plain",
                        },
                      },
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(v.app_id) +
                            "\n                "
                        ),
                      ]
                    )
                  })
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "updated_at",
              label: "上次同步时间",
              width: "160",
              "header-align": "center",
              align: "center",
            },
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              prop: "cancel",
              label: "操作",
              width: "100",
              "header-align": "center",
              align: "center",
              fixed: "right",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onModify(scope.row)
                          },
                        },
                      },
                      [_vm._v("编辑")]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        on: {
                          click: function ($event) {
                            return _vm.onSync(scope.row)
                          },
                        },
                      },
                      [_vm._v("同步")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.totalPage > 0
        ? _c(
            "section",
            { staticClass: "pagination-wrap" },
            [
              _c("el-pagination", {
                attrs: {
                  "current-page": _vm.currentPage,
                  "page-size": _vm.pageSize,
                  layout: "prev, pager, next, jumper",
                  "page-count": _vm.totalPage,
                },
                on: {
                  "current-change": _vm.handleCurrentChange,
                  "update:currentPage": function ($event) {
                    _vm.currentPage = $event
                  },
                  "update:current-page": function ($event) {
                    _vm.currentPage = $event
                  },
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("ConfigManage", {
        ref: "ConfigManage",
        attrs: {
          ruleForm: _vm.chooseData,
          manageType: _vm.manageType,
          fansTagList: _vm.fansTagList,
          multiList: _vm.multipleSelection,
        },
        on: {
          "update:ruleForm": function ($event) {
            _vm.chooseData = $event
          },
          "update:rule-form": function ($event) {
            _vm.chooseData = $event
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }