"use strict";

require("core-js/modules/es7.symbol.async-iterator");
require("core-js/modules/es6.symbol");
require("core-js/modules/es6.string.iterator");
require("core-js/modules/es6.array.from");
require("core-js/modules/es6.regexp.to-string");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/web.dom.iterable");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.number.constructor");
var _wechats = require("@/api/wechats");
var _yunying = require("@/api/yunying");
var _constants = require("@/constants");
function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it.return != null) it.return(); } finally { if (didErr) throw err; } } }; }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; } //
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default2 = exports.default = {
  props: {
    // 操作类型
    manageType: {
      type: String,
      default: ''
    },
    ruleForm: {
      type: Object,
      default: function _default() {
        return {
          title: '',
          // 导粉标签名称
          fansList: [] // 导粉号列表
        };
      }
    }
  },
  data: function data() {
    var _this = this;
    return {
      dialogVisible: false,
      rules: {
        title: {
          required: true,
          message: '请填写导粉标签',
          trigger: 'blur'
        },
        fansList: {
          required: true,
          type: 'array',
          trigger: 'blur',
          validator: function validator(rule, value, callback) {
            var tips = _this.validateFansList(value);
            if (tips) {
              callback(new Error(tips));
            } else {
              callback();
            }
          }
        }
      },
      canClick: true,
      // 是否可以点击提交按钮
      fansGroupList: [] // 导粉组列表
    };
  },
  computed: {
    // 导粉总比例
    totalWeight: function totalWeight() {
      var fansList = this.ruleForm.fansList;
      if (!fansList || fansList.length === 0) {
        return 0;
      }
      var total = 0;
      var _iterator = _createForOfIteratorHelper(fansList),
        _step;
      try {
        for (_iterator.s(); !(_step = _iterator.n()).done;) {
          var item = _step.value;
          total += Number(item.weight);
        }
      } catch (err) {
        _iterator.e(err);
      } finally {
        _iterator.f();
      }
      if (total > 100) {
        this.$message({
          message: '导粉号总比例已超过100%，请调整！',
          type: 'warning'
        });
      }
      return total;
    }
  },
  created: function created() {
    this.FANS_TYPES_MAP = _constants.FANS_TYPES_MAP;
  },
  mounted: function mounted() {
    this.getFansGroupList();
  },
  methods: {
    getFansGroupList: function getFansGroupList() {
      var _this2 = this;
      var params = {
        per_page: 2000,
        page: 1,
        type: '1,2,3,5,6'
      };
      (0, _yunying.getWxList)(params).then(function (_ref) {
        var data = _ref.data,
          code = _ref.code;
        if (code === 200) {
          _this2.fansGroupList = data.list.map(function (v) {
            var label = "".concat(v.qrcode_id, " ").concat(v.name).concat(v.wechat ? ': ' + v.wechat : '', " [").concat(_constants.FANS_TYPES_MAP.get(v.type), "]");
            return {
              label: label,
              value: v.id,
              status: v.status
            };
          });
        }
      });
    },
    // 添加一行导粉配置
    addFans: function addFans() {
      var newItem = {
        value: '',
        weight: 0
      };
      this.ruleForm.fansList.push(newItem);
    },
    // 删除一行导粉配置
    deleteFans: function deleteFans(index) {
      this.ruleForm.fansList.splice(index, 1); // 移除数据
    },
    submitForm: function submitForm(formName) {
      var _this3 = this;
      this.$refs[formName].validate(function (valid) {
        if (valid) {
          _this3.handleEvent();
        } else {
          return false;
        }
      });
    },
    handleEvent: function handleEvent() {
      var _this4 = this;
      if (!this.canClick) {
        this.$message({
          message: '请勿重复提交！',
          type: 'warning'
        });
        return;
      }
      var _this$ruleForm = this.ruleForm,
        id = _this$ruleForm.id,
        title = _this$ruleForm.title,
        fansList = _this$ruleForm.fansList;
      var fansValues = [],
        fansWeights = [];
      fansList.forEach(function (element) {
        fansValues.push(element.value);
        fansWeights.push(element.weight);
      });
      var params = {
        title: title,
        wechat_group: fansValues.join(','),
        weight: fansWeights.join(',')
      };
      if (this.manageType === 'add') {
        (0, _wechats.addWxTag)(params).then(function (_ref2) {
          var code = _ref2.code;
          if (code === 200) {
            _this4.$message({
              message: '新增成功',
              type: 'success'
            });
            _this4.dialogVisible = false;
            _this4.$parent.getList(); // 更新父组件数据
            _this4.canClick = true;
          }
        });
      } else {
        (0, _wechats.modifyWxTag)(id, params).then(function (_ref3) {
          var code = _ref3.code;
          if (code === 200) {
            _this4.$message({
              message: '修改成功',
              type: 'success'
            });
            _this4.dialogVisible = false;
            _this4.$parent.getList(); // 更新父组件数据
            _this4.canClick = true;
          }
        }).catch(function (err) {
          _this4.canClick = true;
        });
      }
    },
    // 校验导粉配置
    validateFansList: function validateFansList() {
      var fansList = this.ruleForm.fansList;
      var tips = '';
      if (!fansList || fansList.length === 0) {
        tips = '请配置导粉号！';
      } else if (this.totalWeight != 100) {
        tips = '总比例需为100%，请调整！';
      } else {
        var seen = {};
        var _iterator2 = _createForOfIteratorHelper(fansList),
          _step2;
        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var item = _step2.value;
            if (!item.value) {
              tips = '请选择导粉号！';
              break;
            }
            // 判断是否重复
            if (seen[item.value]) {
              tips = '存在重复的导粉号配置，请检查修改！';
              break;
            }
            seen[item.value] = true;
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }
      return tips;
    }
  }
};