var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        title: _vm.manageType === "add" ? "新增" : "修改",
        visible: _vm.dialogVisible,
        width: "750px",
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          attrs: {
            model: _vm.ruleForm,
            "label-position": "left",
            "label-width": "100px",
            rules: _vm.rules,
          },
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "导粉标签", prop: "title" } },
            [
              _c("el-input", {
                model: {
                  value: _vm.ruleForm.title,
                  callback: function ($$v) {
                    _vm.$set(_vm.ruleForm, "title", $$v)
                  },
                  expression: "ruleForm.title",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-form-item",
            { attrs: { label: "导粉号", prop: "fansList" } },
            [
              _c(
                "el-card",
                {
                  attrs: {
                    "body-style": "background: #f7f8fa",
                    shadow: "never",
                  },
                },
                [
                  _c(
                    "div",
                    { staticStyle: { "padding-bottom": "20px" } },
                    [
                      _c(
                        "el-button",
                        {
                          attrs: { type: "primary", icon: "el-icon-plus" },
                          on: { click: _vm.addFans },
                        },
                        [
                          _vm._v(
                            "\n                        添加导粉号\n                    "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("span", { staticStyle: { "padding-left": "20px" } }, [
                        _vm._v("总比例：" + _vm._s(_vm.totalWeight) + "%"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm._l(_vm.ruleForm.fansList, function (fansItem, fansIndex) {
                    return _c(
                      "el-form",
                      {
                        key: fansItem.id,
                        staticStyle: { "margin-bottom": "10px" },
                        attrs: { inline: true },
                      },
                      [
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-select",
                              {
                                staticClass: "width-300",
                                attrs: {
                                  placeholder: "请选择导粉号",
                                  filterable: "",
                                },
                                model: {
                                  value: fansItem.value,
                                  callback: function ($$v) {
                                    _vm.$set(fansItem, "value", $$v)
                                  },
                                  expression: "fansItem.value",
                                },
                              },
                              _vm._l(_vm.fansGroupList, function (v, k) {
                                return _c("el-option", {
                                  key: k,
                                  staticClass: "max-width-500",
                                  attrs: {
                                    label: v.label,
                                    value: v.value,
                                    disabled: v.status === 1 ? false : true,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          { attrs: { label: "比例" } },
                          [
                            _c("el-input-number", {
                              staticClass: "width-150",
                              attrs: {
                                min: 0,
                                max: 100,
                                precision: 0,
                                label: "比例",
                              },
                              model: {
                                value: fansItem.weight,
                                callback: function ($$v) {
                                  _vm.$set(fansItem, "weight", $$v)
                                },
                                expression: "fansItem.weight",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "el-form-item",
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { type: "text" },
                                on: {
                                  click: function ($event) {
                                    return _vm.deleteFans(fansIndex)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n                            删除\n                        "
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function ($event) {
                  _vm.dialogVisible = false
                },
              },
            },
            [_vm._v("取 消")]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  return _vm.submitForm("ruleForm")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }