var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", {
            attrs: { label: "导粉号管理", name: "wxconfig" },
          }),
          _vm._v(" "),
          _c("el-tab-pane", { attrs: { label: "分组管理", name: "wxgroup" } }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: { label: "导粉标签管理", name: "fanstag" },
          }),
          _vm._v(" "),
          _c("el-tab-pane", {
            attrs: { label: "企业微信监控", name: "workwechat" },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }