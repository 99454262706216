"use strict";

var _interopRequireDefault = require("/var/www/html/node_modules/@babel/runtime/helpers/interopRequireDefault.js");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
require("core-js/modules/es6.regexp.split");
require("core-js/modules/es6.function.name");
require("core-js/modules/es6.object.freeze");
var _yunying = require("@/api/yunying");
var _wechats = require("@/api/wechats");
var _constants = require("@/constants");
var _configManage = _interopRequireDefault(require("./components/config-manage"));
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// 配置管理
var _default = exports.default = {
  components: {
    ConfigManage: _configManage.default
  },
  data: function data() {
    return {
      loading: true,
      tableData: [],
      searchName: '',
      searchId: '',
      searchTagTitle: '',
      //搜索导粉标签
      fansTagList: [],
      // 导粉标签列表
      // 修改数据
      chooseData: {},
      manageType: '',
      // 操作类型
      currentPage: 1,
      totalPage: 1,
      pageSize: 30,
      multipleSelection: [],
      // 批量操作勾选列表
      styleList: ['', 'success', 'warning', 'danger'],
      colorList: ['blue', 'green', 'yellow', 'red']
    };
  },
  mounted: function mounted() {
    this.getAllWxTagList();
    this.getWxConfigList();
  },
  methods: {
    // 获取标签列表
    getAllWxTagList: function getAllWxTagList() {
      var _this = this;
      var params = {
        page: 1,
        // 要拿到所有的标签列表，暂时设置100，后续超出再调整
        per_page: 100
      };
      (0, _wechats.getWxTagList)(params).then(function (_ref) {
        var code = _ref.code,
          data = _ref.data;
        if (code === 200 && data.list) {
          _this.fansTagList = Object.freeze(data.list);
        }
      });
    },
    // 获取配置列表
    getWxConfigList: function getWxConfigList() {
      var _this2 = this;
      var params = {
        page: this.currentPage,
        per_page: this.pageSize
      };
      if (this.searchName) {
        params.name = this.searchName;
      }
      if (this.searchId) {
        params.wechat = this.searchId;
      }
      if (this.searchTagTitle) {
        params.tag_title = this.searchTagTitle;
      }
      (0, _yunying.getWxGroup)(params).then(function (_ref2) {
        var data = _ref2.data,
          code = _ref2.code;
        _this2.loading = false;
        if (code === 200) {
          _this2.tableData = Object.freeze(data.list);
          _this2.totalPage = data.total_page;
        }
      });
    },
    onSearch: function onSearch() {
      this.getWxConfigList();
    },
    // 分页
    handleCurrentChange: function handleCurrentChange(page) {
      this.currentPage = page;
      this.getWxConfigList();
    },
    // 新增配置
    onAdd: function onAdd() {
      this.chooseData = {
        name: '',
        fansList: [],
        fansTag: ''
      };
      this.manageType = 'add';
      this.$refs.ConfigManage.dialogVisible = true;
    },
    // 修改
    onModify: function onModify(val) {
      var weight = val.weight,
        wechat_group_info = val.wechat_group_info,
        id = val.id,
        name = val.name,
        tag_id = val.tag_id;
      var fansList = wechat_group_info.map(function (v, k) {
        var value = parseInt(v.id);
        var ratio = weight.split(',')[k];
        return {
          value: value,
          weight: ratio
        };
      });
      this.chooseData = {
        id: id,
        name: name,
        fansList: fansList,
        fansTag: tag_id
      };
      this.manageType = 'modify';
      this.$refs.ConfigManage.dialogVisible = true;
    },
    // 批量修改
    onModifyMulti: function onModifyMulti() {
      if (this.multipleSelection.length < 1) {
        this.$message({
          message: '请勾选需要修改的配置项！',
          type: 'warning'
        });
        return;
      }
      this.manageType = 'modifyMulti';
      this.chooseData = {
        fansList: []
      };
      this.$refs.ConfigManage.dialogVisible = true;
    },
    //同步
    onSync: function onSync(_ref3) {
      var id = _ref3.id;
      var loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      (0, _yunying.syncWxGroup)(id).then(function () {
        loading.close();
      });
    },
    handleType: function handleType(s) {
      this.typesVal = s;
      this.getWxConfigList();
    },
    groupFormat: function groupFormat(_ref4) {
      var wechat_name = _ref4.wechat_name,
        wechat = _ref4.wechat,
        type = _ref4.type;
      var tps = _constants.FANS_TYPES_MAP.get(type);
      return "".concat(wechat_name, "\uFF08").concat(tps).concat(wechat ? '：' + wechat : '', "\uFF09");
    },
    // 多选
    handleSelectionChange: function handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 根据标签id获取对应的标签名称
    getFansTagNameById: function getFansTagNameById(id) {
      var fansTagName = id;
      for (var i = 0; i < this.fansTagList.length; i++) {
        var fansTagItem = this.fansTagList[i];
        if (fansTagItem.id === id) {
          fansTagName = fansTagItem.title;
          break;
        }
      }
      return fansTagName;
    }
  }
};